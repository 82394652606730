<template>
  <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
    <template #header>
      <view-search-form :queryInfo.sync="queryInfo" keyword-placeholder="姓名，身份证" :tool-list="['grade', 'keyword']"
        @on-search="renderTable(1)">
        <el-form-item label="非全日制专业" label-width="120">
          <el-select v-model="queryInfo.majorId" clearable filterable size="small" style="width: 260px;">
            <el-option v-for="{ majorName, id } in partTimeMajorList" :key="id" :label="majorName" :value="id" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button">
        <el-button type='success' plain size='small' @click='handleDownloadStuList()'>导出学生名单</el-button>
        <template v-permission="['partTimeStu:add']">
          <upload-excel download-title="下载批量更新模板" import-title="批量更新学生专业" import-btn-type="success" icon="el-icon-refresh"
            :loading.sync="loading" :api="$http.importExcelHttp.importUpdateStudentPartTime"
            @on-download="$http.exportExcelHttp.exportStudentPartTimeUpdateTemplate()" @on-success="renderTable(1)" />
          <el-divider direction="vertical"></el-divider>
          <upload-excel :loading.sync="loading" :api="$http.importExcelHttp.importStudentPartTime"
            @on-download="$http.exportExcelHttp.exportStudentPartTimeTemplate()" @on-success="renderTable(1)" />
          <el-button type="primary" size="small" @click="showDialog()">添加学生
          </el-button>
        </template>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column prop="collegeName" label="二级学院" width="120" />
      <el-table-column prop="majorName" label="专业名字" width="250" />
      <el-table-column prop="amount" label="缴费标准" width="100" />
      <el-table-column prop="grade" label="年级" width="100" align="center" />
      <el-table-column prop="stuName" label="姓名" width="120" />
      <el-table-column prop="idNumber" label="身份证" width="180" />
      <el-table-column prop="examineCode" label="考生号" width="140" />
      <el-table-column prop="fixedPhone" label="联系电话" width="140" />
      <el-table-column prop="payFlagYearOne" label="一学年学费" width="100" align="center">
        <template v-slot="{ row }">
          <div v-html="getTag(row.payFlagYearOne)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="payFlagYearTwo" label="二学年学费" width="100" align="center">
        <template v-slot="{ row }">
          <div v-html="getTag(row.payFlagYearTwo)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="payFlagYearThree" label="三学年学费" width="100" align="center">
        <template v-slot="{ row }">
          <div v-html="getTag(row.payFlagYearThree)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="150" />
      <el-table-column label="操作" fixed="right" width="150">
        <template v-slot="{ row }">
          <el-button v-permission="['partTimeStu:edit:base']" type="primary" size="mini"
            @click="showDialog(row.id, `-${row.stuName}(${row.examineCode})`)">编辑
          </el-button>
          <el-button v-permission="['partTimeStu:del']" size="mini" type="danger"
            @click="del(deleteStudentPartTime, row.id, `学生-${row.stuName}(考号:${row.examineCode})`)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <part-time-student-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog"
      @on-close="renderTable" />
  </table-view>
</template>

<script>
import {
  deleteStudentPartTime,
  getStudentPartTimeList,
  getPartTimeMajors
} from '@/api/studentPartTime'
import tableView from '@/vue/mixins/table-view'
import PartTimeStudentDialog from '@/views/pages/stuInfoPartTime/student-list/component/partTimeStudentDialog.vue'

export default {
  name: 'list',
  components: {
    PartTimeStudentDialog
  },
  mixins: [tableView],
  data() {
    return {
      queryInfo: {
        grade: new Date().getFullYear(),
        majorId: ''
      },
      partTimeMajorList: []
    }
  },
  created() {
    this.getMajorList()
    this.renderTable(1)
  },
  methods: {
    deleteStudentPartTime, // 删除api
    // 获取学生列表
    renderTable(pageNum) {
      this.getTableData(getStudentPartTimeList, pageNum)
    },
    // 获取继续教育专业 list
    getMajorList() {
      getPartTimeMajors({}).then((res) => {
        this.partTimeMajorList = [
          {
            id: '',
            majorName: '所有专业'
          },
          ...res.data
        ]
      })
    },
    getTag(type) {
      if (type === 1) {
        return '<span class="el-tag el-tag--success el-tag--light">已缴</span>'
      } else {
        return '<span class="el-tag el-tag--info el-tag--light">未缴</span>'
      }
    },
    handleDownloadStuList() {
      this.$http.exportExcelHttp.downloadPartTimeStuList(this.queryInfo, '学生名单').then()
    }
  }
}
</script>
